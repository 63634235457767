/* styles/Minesweeper.module.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.grid {
  margin-top: 10px;
}

.row {
  display: flex;
}

.cell {
  @apply m-1.5 rounded-sm w-[44px] h-[44px] z-40;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  background-color: #bbb;
  font-size: 18px;
}

.cell:hover {
  background-color: #999;
}

.revealed {
  @apply bg-primary;
  cursor: default;
}

.flagged {
  background-color: #ffc107;
}
