.score {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #cc00ff; /* Neon purple text */
}

.grid {
  display: inline-block;
  background-color: #2a1b3d; /* Dark purple-gray for the grid */
  padding: 10px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 15px rgba(204, 0, 255, 0.3); /* Neon purple glow effect */
}

.row {
  display: flex;
}

.cell {
  width: 80px;
  height: 80px;
  background-color: #4b2e5a; /* Muted purple for empty cells */
  margin: 5px;
  position: relative;
  border-radius: 5px;
  border: 1px solid #cc00ff; /* Neon purple border */
}

.tile {
  width: 100%;
  height: 100%;
  line-height: 80px;
  text-align: center;
  font-size: 2rem;
  color: #e0e1dd; /* Light gray text for contrast */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(204, 0, 255, 0.5); /* Neon purple glow */
}

.tile-2 {
  background-color: #3d2a69; /* Dark purple */
}

.tile-4 {
  background-color: #5a3d8a; /* Slightly lighter purple */
}

.tile-8 {
  background-color: #ff00cc; /* Neon pink-purple */
  color: #e0e1dd;
}

.tile-16 {
  background-color: #ff33ff; /* Brighter neon magenta */
  color: #e0e1dd;
}

.tile-32 {
  background-color: #ff66ff; /* Soft neon purple-magenta */
  color: #e0e1dd;
}

.tile-64 {
  background-color: #cc00ff; /* Pure neon purple */
  color: #e0e1dd;
}

.tile-128 {
  background-color: #9900cc; /* Darker neon purple */
  color: #e0e1dd;
}

.tile-256 {
  background-color: #b300e6; /* Bright purple */
  color: #e0e1dd;
}

.tile-512 {
  background-color: #cc33ff; /* Light neon purple */
  color: #e0e1dd;
}

.tile-1024 {
  background-color: #e066ff; /* Pastel neon purple */
  color: #0d1b2a; /* Dark text for contrast */
}

.tile-2048 {
  background-color: #ff00ff; /* Vibrant neon magenta */
  color: #0d1b2a;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.8); /* Stronger purple glow */
}
